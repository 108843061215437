import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import Footer from "../components/footer"
import GridItemQuote from "../components/grid-item-quote"
import GridItemWithHeading from "../components/grid-item-with-heading"
import Header from "../components/header"
import PortfolioGrid from "../components/portfolio-grid"
import SEO from "../components/seo"
// import "../styles/index.scss"

export default class PortfolioSingleTemplate extends React.Component {
  componentDidMount() {
    disableBodyScroll()
  }
  componentWillUnmount() {
    clearAllBodyScrollLocks()
  }
  render() {
    const { image1, image2, image3, quote } = {
      ...this.props.data.portfolio.frontmatter,
    }
    const portfolioSlug = this.props.data.portfolio.fields.slug
    const { portfolioSingleImage, description, title, metaImage } = {
      ...this.props.data.porfolioSingle.frontmatter,
    }
    return (
      <div>
        <SEO
          title={title}
          description={description}
          metaImage={metaImage}
          pathname={this.props.location.pathname}
        />
        <Header></Header>
        <main className="grid grid-col-1-2-3">
          <div className="grid-item-1x2">
            <GridItemWithHeading
              image={image1.childImageSharp.fluid}
              heading={title}
            ></GridItemWithHeading>
          </div>
          <div className="grid-item-1x1">
            <Img fluid={image2.childImageSharp.fluid} />
          </div>
          <div className="grid-item-1x1 grid-item-center-mw-400">
            <GridItemQuote quote={quote}></GridItemQuote>
          </div>
          <div className="grid-item-1x1">
            <PortfolioGrid></PortfolioGrid>
            {/* // TODO Component for modal */}
            <div className="modal-container portfolioSingle">
              <div className="modal open">
                <Link to={portfolioSlug} className="modal-close">
                  Close
                </Link>
                <Img fluid={portfolioSingleImage.childImageSharp.fluid} />
              </div>
            </div>
          </div>
          <div className="grid-item-1x1">
            <Img fluid={image3.childImageSharp.fluid} />
          </div>
        </main>
        <Footer></Footer>
      </div>
    )
  }
}

// TODO Seperate graphql file? Sharing images on other templates?
export const query = graphql`
  query($slug: String!) {
    porfolioSingle: markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        description
        title
        portfolioSingleImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        metaImage {
          childImageSharp {
            fixed {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
      fields {
        slug
      }
    }
    portfolio: markdownRemark(
      frontmatter: { templateKey: { eq: "portfolio" } }
    ) {
      frontmatter {
        image1 {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image2 {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image3 {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
        quote
        metaImage {
          childImageSharp {
            fixed {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
      fields {
        slug
      }
    }
  }
`
